import './WorkComponent.scss'
import '../pages/Main.scss'
const WorkComponent = (props : {type: number, title: string, role: string, tech: string, thumbnail: string, description: string}) => {
    if(props.type === 1){
        return (
            <div className={"workComponent"}>
                <p className={"projectTitle"}>{props.title}</p>
                <div className={"projectTechRole"}>
                    <p className={"tech"}>{props.tech}</p>
                    <p className={"role"}>{props.role}</p>
                </div>
                <div className={"projectDescriptionImg"}>
                    <div className={"thumbnail"}>
                        <img src={props.thumbnail} alt={""}/>
                    </div>

                    <p className={"description"}>{props.description}</p>
                </div>
            </div>
        )
    }
    else{
        return (
            <div className={"workComponent"}>
                <p className={"projectTitle"}>{props.title}</p>
                <div className={"projectTechRole"}>
                    <p className={"role"}>{props.role}</p>
                    <p className={"tech"}>{props.tech}</p>
                </div>
                <div className={"projectDescriptionImg"}>
                    <p className={"description"}>{props.description}</p>
                    <div className={"thumbnail"}>
                        <img src={props.thumbnail} alt={""}/>
                    </div>

                </div>
            </div>
        )
    }

}

export default WorkComponent