import './About.scss'
import BackButton from "../components/BackButton";
const About = () => {
    return (
        <div className={"about"}>
            <div className={"backButtonDiv"}>
                <BackButton/>
            </div>
            <p className={"MarsFixed"}>Mars Williams</p>
            <p className={"developerFixed"}>Full Stack Developer</p>
            <div className={"aboutContainer"}>
                <p className={"hello"}>Hello!</p>
                <p>My name is Mars Williams, and I am currently a Full Stack Developer based out of Indiana. I have been coding since 2015, but have become a dedicated full stack web developer since 2021. I have the most experience with ReactJS and AdonisJS, but am comfortable with most tech stacks and am always willing to push myself and learn something new! My first language I ever worked with was C++, but I've had experience with C, Python, PhP, Java, JavaScript, HTML, CSS, SQL, Kotlin and Typescript. I'm always up for a challenge, and would be excited to work with you!</p>
                <p>For professional inquiries, I can be contacted at marswilliams626@gmail.com</p>
                <p>Thank you for stopping by!</p>
            </div>
        </div>
    )
}
export default About