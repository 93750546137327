import "./Main.scss"
import WorkComponent from "../components/WorkComponent";
import TLZ from '../assets/TLZThumbnail.png'
import DualCredit from '../assets/DualCreditThumbnail.png'
import ClassroomAssurance from '../assets/ClassroomAssuranceThumbnail.png'
import HGM from '../assets/HGMThumbnail.png'
import BackArrow from '../assets/BackArrow.svg'
import {useRef} from "react";
const Main = () => {

    const workRef = useRef<null | HTMLDivElement>(null);
    const introRef = useRef<null | HTMLDivElement>(null);
    const handleWorkClick = () => {
        workRef.current?.scrollIntoView({behavior: 'smooth'});
    };
    const handleUpClick = () => {
        introRef.current?.scrollIntoView({behavior: 'smooth'});
    };


    return (
        <div className={"main"}>
            <div className={"intro"} ref={introRef}>
                <p className={"Mars"}>Mars Williams</p>
                <p className={"developer"}>Full Stack Developer</p>
                <div className={"introButtons"}>
                    <button onClick={handleWorkClick}>My Work</button>
                    <a href={"/resume"}>Resume</a>
                    <a href={"/about"}>About Me</a>
                </div>
            </div>
            <div className={"work"} ref={workRef}>
                <p className={"MarsSticky"}>Mars Williams</p>
                <p className={"developerSticky"}>Full Stack Developer</p>
                <div className={"upButton"}>
                    <img src={BackArrow} onClick={handleUpClick}/>
                </div>

                <div className={"workDiv"}>
                    <WorkComponent type={1} title={"The Loading Zone"} role={"Lead Developer • Web App"} tech={"ReactTS, AdonisJS, AWS S3"} thumbnail={TLZ} description={"The Loading Zone National Distribution is a website which will allow for PBS stations across the nation to download and distribute episodes of The Loading Zone, one minute interstitials teaching children about STEM. The website also includes an admin panel which can be used to add/delete/update episodes and view statistics on which episodes and PBS stations have been most active on the site. I was the sole backend developer and a lead frontend developer for this project. I used AdonisJS to create the backend which handles any data about episodes, users, PBS stations, downloads, and statistics along with querying an AWS S3 bucket for episode download links. I also created all the logic for the frontend of the site which uses ReactTS."}/>
                    <WorkComponent type={2} title={"BSU Dual Credit Site"} role={"Lead Developer • Web App"} tech={"ReactJS, Wordpress API, FramerMotion"} thumbnail={DualCredit} description={"BSU Dual Credit is a web application which allows for high school students and their parents to browse possible career paths and their applicable majors along with available dual credit options offered through Ball State University. My role in this project was to style the desktop version of the site and to add functionality to the site. I also added animations using Framer Motions to transition smoothly between pages. "}/>
                    <WorkComponent type={1} title={"Classroom Assurance Tool"} role={"Developer • Web App"} tech={"ReactTS, AdonisJS, SAML2-JS"} thumbnail={ClassroomAssurance} description={"Classroom Assurance is a web application which displays all in-session classes occurring on Ball State University Campus to allow for administrators to ensure specific classes in their department are running as they should. Along with the main class catalog there is also a dashboard to view all the data entered by administrators. While I helped with some of the frontend for the site, my main role was to set up the single sign on integration using the Ball State University Federate system and SAML2-JS. This included creating metadata for our service provider and creating all the logic to pull a user's information such as the departments and classes they would be allowed to access."}/>
                    <WorkComponent type={2} title={"Henry Gets Moving"} role={"Lead Developer • Web App"} tech={"ReactTS, AdonisJS"} thumbnail={HGM} description={"Henry Gets Moving is a website based on the book Henry Gets Moving by Chaz Nielsen and Pierre Rouzier. The site encourages both parents and children to get moving and be more healthy by offering a plethora of exercises and recipes to try out. It motivates kids through daily exercise goals which parents can track through the exercise log function. The site also includes various games to teach children about being healthy. My role in the project was to create the entire backend for the project using AdonisJS. I also worked on the frontend of the site by adding functionality to many of the pages and hooking up the backend."}/>
                </div>
            </div>
        </div>
    )
}
export default Main