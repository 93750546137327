import React from 'react';
import {Routes, Route} from "react-router-dom";
import './App.css';
import Main from "./pages/Main";
import Resume from "./pages/Resume";
import About from "./pages/About";

function App() {
  return (
      <div className={"App"}>
          <Routes>
            <Route path="/" element={<Main/>}/>
            <Route path="/resume" element={<Resume/>}/>
            <Route path="/about" element={<About/>}/>
          </Routes>
      </div>
  );
}

export default App;
