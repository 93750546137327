import './Resume.scss'
//@ts-ignore
import resume from '../assets/Resume-MarsWilliams.pdf'
import BackButton from "../components/BackButton";
const Resume = () => {
    return (
        <div className={"resumeDiv"}>
            <div className={"backButtonDiv"}>
                <BackButton/>
            </div>
            <p className={"MarsFixed"}>Mars Williams</p>
            <p className={"developerFixed"}>Full Stack Developer</p>
            <div className={"resumeContainer"}>
                <iframe className={"resume"} src={resume} title={"resume"}/>
            </div>
        </div>
    )
}
export default Resume